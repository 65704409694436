import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { Box, Button, TextField, Typography, useMediaQuery } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from "../../assets/Mainlogo/logo.png";
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import icon from "../../assets/sub-logos/icon.png";
// import Checkbox from '@mui/material/Checkbox';
// import Signup from "../Signup";



// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Navbar = () => {

    const [isMobile, setIsMobile] = useState(false);
    const isDesktop = useMediaQuery("(min-width: 960px");

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card sx={{ minWidth: 275 }}>
                <nav className="navbar">
                    <img src={logo} alt="logo" className="mainlogo" />
                    <ul
                        className={isMobile ? "nav-links-mobile" : "nav-links"}
                        onClick={() => setIsMobile(false)} >
                        <Link to="/" id="home" className="home">
                            <li>Home</li>
                        </Link>
                        <Link to="/services" id="services" className="services">
                            <li>Our Services</li>
                        </Link>
                        <Link to="/emart" id="emart" className="emart">
                            <li>Emart</li>
                        </Link>
                        <Link to="/contact" id="contact" className="contact">
                            <li>ContactUs</li>
                        </Link>
                        {/* <Button variant="outlined"onClick={handleClickOpen} style={{ borderColor: "#F28688",borderRadius: "30px", color:"#F28688"  }}>
                                <Link href="#" style={{ color: "#F28688" }}></Link>Login
                            </Button> */}
                        <Link to="#" className="login" onClick={handleClickOpen}>
                            <li >Login</li>
                        </Link>
                        {/* <Button style={{ border: "4px solid #F08D32", borderRadius: "30px", color: "#000000" }} className="login"
                            onClick={handleClickOpen}>Login</Button> */}
                    </ul>
                    {isMobile || !isDesktop ? (
                        <Button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)} >
                            {isMobile ? (
                                //   <i className="fas fa-times"></i>
                                <CloseIcon style={{ color: "#000" }} />
                            ) : (
                                //   <i className="fas fa-bars"></i>
                                <MenuIcon className="MenuIcon" style={{ color: "#000" }} />
                            )}
                        </Button>
                    ) : null}
                </nav>
            </Card>

            <Dialog style={{ width: "-webkit-fill-available" }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div id="login-page" style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px" }}>
                    <div className="image-section" style={{ width: "30%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <img src={logo} alt="" />
                    </div>
                    <div className="btn-section" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <Button className="login-btn" variant="outlined" style={{ borderRadius: "100px", color: "#FFFFFF", fontWeight: "bold" }}>
                            <img src={icon} alt="" style={{ width: "30px", height: "30px" }} />
                            Login
                        </Button>
                    </div>
                </div>
                <Box  sx={{ display: "flex",   flexDirection: "column", width:"100%", pl:2, pr:2}}>
                    <Typography variant="h4" style={{ color: "#000000", textAlign: "center" }}>Login</Typography>
                    <div className="users-field" style={{ display: "flex", flexDirection: "column", gap:"10px", marginTop:"10px"}}>
                        <label>Username</label>
                        <TextField size="small" placeholder="Enter user name"  />
                        <label>Password</label>
                        <TextField size="small" type="password" placeholder="Enter password" />
                        {/* <Typography variant="p2" >Forgot Password ?</Typography> */}
                        <Button variant="outlined" sx={{ bgcolor: "#FFA433", ":hover":{bgcolor: "#FFA433"}, color: "#FFFFFF", fontWeight: "bold", width:"100%",mt:2 }} onClick={handleClose}>Login</Button>
                    </div>

                </Box>
                <Typography variant="h6" style={{ textAlign: "center", padding: "5px" }}>Or</Typography>
                <Typography href="../Signup" variant="a" style={{ textAlign: "center", textDecorationLine: "underline", padding: "5px" }} >
                    Let’s Create New One
                </Typography>
                <div style={{ display: "flex", justifyContent: "flex-end", padding: "25px" }}>
                    <Button variant="outline" style={{ border: "1px solid #000000", color: "#000000" }} onClick={handleClose}>Close</Button>
                </div>
            </Dialog>
            {/* <div id="singnup-page">
            <Card 
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px" }}>
                    <div className="image-section" style={{ width: "30%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <img src={logo} alt="" />
                    </div>
                    <div className="btn-section" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <Button className="login-btn" variant="outlined" style={{ borderRadius: "100px", color: "#FFFFFF", fontWeight: "bold" }}>
                            <img src={icon} alt="" style={{ width: "30px", height: "30px" }} />
                            Signup
                        </Button>
                    </div>
                </div>
                <div className="user-login" style={{ display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: "column", padding: "10px" }}>
                    <Typography variant="h4" style={{ color: "#000000", textAlign: "center" }}>Login</Typography>
                    <div className="users-field" style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", padding: "10px" }}>
                        <label>Your name</label>
                        <input type="text" htmlFor="your name" defaultValue="Enter Your Name" />
                        <label>E-mail id or phone number</label>
                        <input type="text" htmlFor="your email" defaultValue="Enter Your Email" />
                        <label>Password</label>
                        <input type="text" htmlFor="your password" defaultValue="Enter Your Password" />
                        <label>Re-enter password</label>
                        <input type="text" htmlFor="re-enter your password" defaultValue="Re-Enter Your Password" />
                        <Checkbox {...label} />
                        <Typography variant="p2" >By creating an account, you agree to our 
                            Conditions of Use and Privacy Notice.
                        </Typography>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: "column", padding: "10px" }}>
                    <Button variant="outlined" style={{ backgroundColor: "#FFA433", borderColor: "#FFA433", color: "#FFFFFF", fontWeight: "bold" }} onClick={handleClose}>Signup</Button>
                </div>
                <Typography variant="h6" style={{ textAlign: "center", padding: "5px" }}>Or</Typography>
                <Typography href="#" variant="a" style={{ textAlign: "center", textDecorationLine: "underline", padding: "5px" }} onClick={handleClose}>
                    Already have an account ? Signin
                </Typography>
                <div style={{ display: "flex", justifyContent: "flex-end", padding: "25px" }}>
                    <Button variant="outline" style={{ border: "1px solid #000000", color: "#000000" }} onClick={handleClose}>Close</Button>
                </div>
            </Card>
            </div> */}
        </>
    );
};

export default Navbar;