import slide1 from "../../../assets/SliderImage/slide1.png"; // ../../../assets/SliderImage/slide1.png
import slide2 from "../../../assets/SliderImage/slide2.png";
import slide3 from "../../../assets/SliderImage/slide3.png";
import slide4 from "../../../assets/SliderImage/slide4.png";
import slide5 from "../../../assets/SliderImage/slide5.png";


const sliderImage = [
  {
    urls: slide1,
  },
  {
    urls: slide2,
  },
  {
    urls: slide3,
  },
  {
    urls: slide4,
  },
  {
    urls: slide5,
  },
];

export default sliderImage;