import React from "react";

const Emart = () => {
    return(
       <>
       <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"80vh"}}>
        <h1>Coming Soon</h1>
       </div>
       </>
    )
}

export default Emart;