import React, { useEffect, useState } from "react";
import "../Home/styles/index.css"; 
import SliderContent from "../../components/Home/Carousel/SliderContent"; 
import Dots from "../../components/Home/Carousel/Dots";
import Arrows from "../../components/Home/Carousel/Arrow"; 
import sliderImage from "../../components/Home/Carousel/SliderImage"; 
import "../../components/Home/Carousel/Slider.css";
import { Box, Button, Typography } from "@mui/material";
import piechart from "../../assets/sub-logos/piechart.png"; 
import Group from "../../assets/sub-logos/Group.png"; 
import bookreader from "../../assets/sub-logos/bookread.png"
import crown from "../../assets/sub-logos/crown.png"
import Rectangle from "../../assets/sub-logos/Rectangle.png";
import RectangleSM from "../../assets/sub-logos/RectangleSM.png";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const ourSpecifications = [ 
  {
    image: piechart,
    title: "Completed Projects",
    number: "30 +"
  },
  {
    image: Group,
    title: "Overall Schools Covered",
    number: "1.5K"
  },
  {
    image: bookreader,
    title: "Years Of Experience",
    number: "18 Years"
  },
  {
    image: crown,
    title: "Awards Winning",
    number: "50 +"
  },
]


const len = sliderImage.length - 1;

function Home(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <>
      <div className="slider-container">
        <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
        <Arrows
          prevSlide={() =>
            setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
          }
          nextSlide={() =>
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
          }
        />
        <Dots
          activeIndex={activeIndex}
          sliderImage={sliderImage}
          onclick={(activeIndex) => setActiveIndex(activeIndex)}
        />
      </div>

      <div className="specific-container">
        {ourSpecifications.map((item, index) => (
          <Box key={index} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "20px" }}>
            <img src={item.image} className="img-fluid" alt="" style={{ alignItems: "center", height: "15vh" }} />
            <Typography variant="h6" className="item-title">{item.title}</Typography>
            <Typography variant="h4" className="item-number">{item.number}</Typography>
          </Box>
        ))}
      </div>
      <div className="brouchure-container">
        <div className="contact-section">
          <img className="brouchimage1" src={RectangleSM} alt="" />
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
            <Typography variant="h5" style={{ padding: "10px", fontWeight: "bolder" }}><span style={{ color: "#F97C2A" }}>Ge</span><span style={{ color: "#F35F23" }}>t</span>
            <span style={{ color: "#E4030F" }}> A Free</span><span style={{ color: "#0049D7" }}> Con</span><span style={{ color: "#2D3BDC"}}>su</span>
            <span style={{ color: "#CA0AEC" }}><span style={{ color: "#6829E1" }}>lt</span><span style={{ color: "#8520E4" }}>a</span>tion</span></Typography>
            <div className="contact-text">
              <Typography variant="p" style={{ padding: "10px"  }}>Please Contact Us</Typography>
              <input type="text" placeholder="enter your email" style={{ padding: "10px", borderRadius: "20px"  }} />&nbsp;
              <Button variant="outlined" style={{ padding: "5px", border: "2px solid ", borderColor: "#FFA537", color: "#000", borderRadius: "30px", fontWeight: "bold" }}>Submit</Button>
            </div>
          </div>
        </div>

        <div className="brouchure-section">
          <img className="brouchimage2" src={Rectangle} alt="" />
          <div className="head-text">
          <Typography variant="p" >Wants to know about the materials
            and more details.
          </Typography>
          <div className="brouchure-text">
            <Button className="brouchure-btn" variant="outlined" style={{ fontWeight: "bold", borderRadius: "30px", color: "#FFF", borderColor: "#FFA537", backgroundColor: "#FFA537" }}>
              <DownloadForOfflineIcon />&nbsp;
              Download Brochure
            </Button>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;