import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import imageSlide from "../../components/OurService/ServiceSlide.jsx"; // ./ServiceSlide
import "../OurServices/styles/index.css"; // ./Ourservices.css
import { useTheme } from '@mui/material/styles';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import IconButton from '@mui/material/IconButton';
import portfolioimg1 from "../../assets/PortfolioImages/portfolioimg1.png";
import portfolioimg2 from "../../assets/PortfolioImages/portfolioimg2.png";
import portfolioimg3 from "../../assets/PortfolioImages/portfolioimg3.png";
import portfolioimg4 from "../../assets/PortfolioImages/portfolioimg4.png";
import portfolioimg5 from "../../assets/PortfolioImages/portfolioimg5.png";
import portfolioimg6 from "../../assets/PortfolioImages/portfolioimg6.png";
import whiteBoard from "../../assets/ProductImages/whiteboard.png";
import wallmountSpeaker from "../../assets/ProductImages/wallmountSpeaker.png";
import ampliFier from "../../assets/ProductImages/ampliFier.png";
import kiosktouchscreen from "../../assets/ProductImages/kiosktouchscreen.png";
import UPS from "../../assets/ProductImages/UPS.png";
import cables from "../../assets/ProductImages/cables.png";
import subjectcontent from "../../assets/ProductImages/subjectcontent.png";
import projector from "../../assets/ProductImages/projector.png";
import battery from "../../assets/ProductImages/battery.png";
import wirelessMic from "../../assets/ProductImages/wirelessMic.png";
import scanner from "../../assets/ProductImages/scanner.png";
import woodenPodium from "../../assets/ProductImages/woodenPodium.png";
import Carousel from 'react-elastic-carousel';
import materialimg from "../../assets/ProductImages/materialimg.png";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import shopimage from "../../assets/shoppage/shopimg.png";
import mailbgimg from "../../assets/MailsectionImg/mailbgimg.png";
import { TextField, Stack } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';


const Ourservices = () => {

  const theme = useTheme();

  const [currentState, setCurrentState] = useState(0)

  const getPrevious = () => {
    if (currentState === 2) {
      setCurrentState(0)
    } else {
      setCurrentState(currentState + 1)
    }
  }
  const getNext = () => {
    if (currentState === 2) {
      setCurrentState(0)
    } else {
      setCurrentState(currentState + 1)
    }
  }

  const listing = [
    {

      image: portfolioimg1,
      title: "Conference Hall"
    },
    {

      image: portfolioimg2,
      title: "Smart Class"
    },
    {

      image: portfolioimg3,
      title: "Smart Meeting Room"
    },
    {
      id: 4,
      image: portfolioimg4,
      title: "Lecture Capture Classroom"
    },
    {
      id: 5,
      image: portfolioimg5,
      title: "Campus wise Placements"
    },
    {
      id: 6,
      image: portfolioimg6,
      title: "Lecture Hall"
    },
  ]

  const ourProductsImg = [
    {
      img: whiteBoard,
      title: "Interactive White Board"
    },
    {
      img: wallmountSpeaker,
      title: "Wall Mount Speaker"
    },
    {
      img: ampliFier,
      title: "Amplifier"
    },
    {
      img: kiosktouchscreen,
      title: "Kiosk with touchscreen"
    },
    {
      img: UPS,
      title: "UPS"
    },
    {
      img: cables,
      title: "Necessary Cables, Power Cable, VGA Cable, LAN Cable"
    },
    {
      img: subjectcontent,
      title: "Education Related Animated content for all subjects"
    },
    {
      img: projector,
      title: "Ceiling Mounted Projector"
    },
    {
      img: battery,
      title: "Batterty with stand"
    },
    {
      img: wirelessMic,
      title: "Wireless handheld Mic"
    },
    {
      img: scanner,
      title: "Document Scanner"
    },
    {
      img: woodenPodium,
      title: "Wooden Podium"
    },
  ]

  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //         if(currentState===2){
  //             setCurrentState(0)
  //         }else {
  //             setCurrentState(currentState+1)
  //         }
  //     }, 5000)
  //     return () => clearTimeout(timer)
  // },[currentState])
  const bgImageStyle = {
    backgroundImage: `url(${imageSlide[currentState].url})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
  }
  const goToNext = (currentState) => {
    setCurrentState(currentState)
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1200, itemsToShow: 3, itemsToScroll: 3 }
  ];

  const BtnList = ["Smart Class", "White Board", "Wall mounted speaker", "Audio amplifier", "Kiosk", "UPS", "USB, VGA, Power cable", "Wooden podium", "Ceiling mounted projector", "Wireless handheld mic", "Document scanner", "Call soft software", "Battery with stand"];

  const [selectedItem, setSelectedItem] = useState(0);
  
  const handleClick = (selectedItem) => {
    setSelectedItem(selectedItem);
  };

  const smartMaterials = [
    {
      title: "Smart Class",
      img: materialimg,
      subtitle: "About",
      para1: "Smart Classroom is a specially designed computer enabled classroom and has interactive learning software which provides teachers with essential tools to manage classrooms, assess students and encourage collaboration.",
      para2: "Smart Classroom is designed to integrate with the existing classroom technology and also with school's wireless or wired network. The need to improve the quality of education to global standards is contributing to the increase in adoption of smart classrooms in India.",
      para3: "Smart Classroom helps in making classroom essions more interactive by incorporating live videos, audio, and pictures. This helps in improving students' engagement with learning. With advances in content creation technology and availability of online content, the proportion of digital content in course curriculum has increased.",
      subtit: "Our Smart Class Projects",
      list1: "86 Smart Classroom in chennai Corporation Schools through ELCOT.",
      list2: "171 Adi Dravidar & Tribal Welfare Department schools through ELCOT.",
      list3: "25 Smart classrooms through Tirunelveli Corporations.",
      list4: "15 Kallar (Most Denotified Backward Class) schools in districts of Madurai, Theni and Dinugal Through ELCOT.",
      list5: "13 Thanjavur Corporation Schools through ELCOt",
      list6: "4 Smart classrooms through ELCOT by Coimbatore Corporations.",
      list7: "38 Smart clarooms through Government aieded Schools",
      list8: "1 Government Children Home for Boys, Ranipet through ELCOT",
      list9: "Netcom e-Smart Class Rooms more than 300 Government and Corporation Schools through ELCOT in BOOT model."
    },
    {
      img: whiteBoard,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate..."
    },
    {
      img: wallmountSpeaker,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000",
      list3: "600dp, Signal Tracking Rate...",
    },
    {
      img: ampliFier,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: kiosktouchscreen,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: cables,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: woodenPodium,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: projector,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: wirelessMic,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: scanner,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: subjectcontent,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    },
    {
      img: battery,
      title: "About the product",
      body: "Here is the short description about the product whiteboard and its specification, moreover those are all the given products are used for the setup on our smart classes and ICT lab services.",
      subtit: "Features of the product",
      list1: "Interactive White Board size 78",
      list2: "Resolution 21300x12000,600dp",
      list3: "Signal Tracking Rate...",
    }
  ]


  // const handleClickScroll = () => {
  //   const element = document.getElementById('section-1');
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [telNo, setTelNo] = useState('')

  // function handleSubmit(event) {
  //     event.preventDefault();
  //     console.log(firstName, lastName, email, telNo)
  // }

  return (
    <>
      <div className="container-style">
        <Typography variant="h3" style={{ fontWeight: "bold" }}>Our Services</Typography>
      </div>
      <div className="main-box">
        <Card className="title-card" sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: 'center', padding: "20px", width: "80%", margin: 0, backgroundColor: "#FFA433", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", borderTopLeftRadius: "40px", borderTopRightRadius: "40px" }}>
          <IconButton aria-label="previous" style={{ display: "flex", justifyContent: "flex-start" }} onClick={getPrevious}>
            {theme.direction === 'rtl' ? <KeyboardDoubleArrowRightIcon style={{ width: "30vw", height: "40vh", color: "#FFF" }} /> : <KeyboardDoubleArrowLeftIcon style={{ color: "#FFF" }} />}
          </IconButton>
          <CardContent className="main-content" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", padding: "0px" }}>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }} variant="h4" >
              {imageSlide[currentState].title}
            </Typography>
          </CardContent>
          <IconButton aria-label="next" onClick={getNext}>
            {theme.direction === 'rtl' ? <KeyboardDoubleArrowLeftIcon style={{ width: "30vw", height: "40vh", color: "#FFF" }} /> : <KeyboardDoubleArrowRightIcon style={{ color: "#FFF" }} />}
          </IconButton>
        </Card>
        <Divider sx={{ backgroundColor: "#FFFFFF", width: "80%", height: "2px" }} />
        <Card sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "80%", height: "60vh", margin: 0, backgroundColor: "#FFA433", padding: "15px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
          <Box sx={{ width: "40%", }}>
            <CardContent className="main-content" style={{ fontStyle: "flex-start" }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold", fontSize: "25px" }} variant="p" >
                {imageSlide[currentState].body}
              </Typography>
            </CardContent>
          </Box>
          <Box sx={{ width: "400px", height: "300px" }} >
            <CardMedia style={bgImageStyle} >
              {
                imageSlide.map((imageSlide, currentState) => (
                  <span key={currentState} onClick={() => goToNext(currentState)}></span>
                  // {`(min-width:600px) matches: ${matches}`}
                ))
              }
            </CardMedia>
          </Box>
        </Card>
        <div style={{ backgroundColor: "#FFA433", display: "flex", flexDirection: "row", width: "80%", height: "20%", justifyContent: "center", padding: "10px", borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px" }}>
          <Button variant="contained" style={{ backgroundColor: "#FFF", color: "#000", border: "1px solid #000" }}>Know More</Button>
        </div>
      </div>
      <div className="container-style">
        <Typography variant="h3" style={{ fontWeight: "bold" }}>Our Portfolio</Typography>
      </div>
      <div className="container">
        {listing.map(({ id, image, title }) => (
          <div className="main-product" key={id}>
            <img alt={title} className="zoom" src={image} />
            <div style={{ display: "flex", padding: "5px" }}>
              <Typography variant="h5" component="h5" style={{ fontWeight: "bold", padding: "5px" }}>{title}</Typography>
            </div>
          </div>
        ))}
      </div>
      <div className="container-style">
        <Typography variant="h3" style={{ fontWeight: "bold" }}>Our Star Products</Typography>
      </div>
      <div className="arrow" style={{ padding: "20px" }}>
        <Carousel breakPoints={breakPoints} pagination={false} showArrows={true}>
          {
            ourProductsImg.map((item) => (

              <Card className="product-image" style={{ width: "250px", height: "200px", display: "grid", gridTemplateColumns: "auto auto auto", alignItems: "center", justifyContent: "center" }}>
                <img src={item.img} style={{ width: "200px" }} alt="" />
                <div className="img-content">{item.title}</div>
              </Card>

            ))
          }
        </Carousel>
      </div>
      <div className="container-style">
        <Typography variant="h3" style={{ fontWeight: "bold" }}>Smart Classroom Materials</Typography>
      </div>
      <div className="materials-section">
        <Card sx={{ width: "80%", height: "100%", backgroundColor: "#FFF", display: "flex", flexDirection: "column", borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px", border: "1px solid #000" }}>
          <div className="headtext" style={{ width: "100%", backgroundColor: "#FFA433", display: "flex", flexDirection: "row", textAlign: "center", justifyContent: "center" }}>
            <Typography variant="h5" style={{ color: "#FFF", padding: "10px", fontWeight: "bold" }}>Materials and Its specs for Smart class</Typography>
          </div>
          <div className="view-products">
            <div className="product-lists">
              <div className="scroll-bar" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", backgroundColor: "#FFA433" }}>
                {BtnList.map((item, index) => (
                  <Button variant="outlined" key={index} onClick={() => handleClick(index)} style={{ border: "none", color: "#FFF", textTransform: "initial", cursor: "pointer", backgroundColor: selectedItem === index ? "red" : "none" }}>
                    <Typography variant="h6" component="h6">{item}</Typography>
                  </Button>
                ))}
              </div>
            </div>
            <div className="product-details">

              <div className="details-bar">
                {selectedItem === 0 ? <Typography variant="h4" component="h4" style={{ color: "#000", fontWeight: "bold" }}>{smartMaterials[selectedItem].title}</Typography> : <Box component={"img"} src={smartMaterials[selectedItem].img} alt="" />}
                {selectedItem === 0 ? <img src={smartMaterials[selectedItem].img} alt="img" /> : <Typography variant="h5" component="h5">{smartMaterials[selectedItem].title}</Typography> }
                {selectedItem === 0 ? <Typography variant="h5" component="h5">{smartMaterials[selectedItem].subtitle}</Typography> : <Typography variant="p" component="p">{smartMaterials[selectedItem].body}</Typography> }
                
                {selectedItem === 0 ? <Typography variant="p" component="p">{smartMaterials[selectedItem].p}</Typography> : <Typography variant="h5" component="h5">{smartMaterials[selectedItem].subtit}</Typography> }
                <Box style={{ display: selectedItem === 0 ? "block" : "inherit" }} >
                  <Typography variant="p" component="p" style={{ padding: "5px" }}>{smartMaterials[selectedItem].para1}</Typography>
                  <Typography variant="p" component="p" style={{ padding: "5px" }}>{smartMaterials[selectedItem].para2}</Typography>
                  <Typography variant="p" component="p" style={{ padding: "5px" }}>{smartMaterials[selectedItem].para3}</Typography>
                </Box>
                {selectedItem === 0 ? <Typography variant="h5" component="h5">{smartMaterials[selectedItem].subtit}</Typography> : <Typography variant="p" component="p">{smartMaterials[selectedItem].li}</Typography> }
                <Box style={{ display: selectedItem === 0 ? "block" : "inherit", bgcolor: "red" }}>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list1}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list2}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list3}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list4}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list5}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list6}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list7}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list8}</li>
                  <li style={{ padding: "10px" }}>{smartMaterials[selectedItem].list9}</li>
                </Box>
              </div>
              {/* {selectedItem && (
                <div className="details-bar">
                   <img src={smartMaterials.img} alt="" />
                   <Typography variant="h5" component="h5">{smartMaterials.title}</Typography>
                   <Typography variant="p" component="p">{smartMaterials.body}</Typography><br />
                 <Typography variant="h5" component="h5">{smartMaterials.subtitle}</Typography>
                 <List>{smartMaterials.list1}</List>
                 <List>{smartMaterials.list2}</List>
                 <List>{smartMaterials.list3}</List>
                </div>
              )} */}
            </div>
            {/* <div className="product-details">
              <div className="details-bar">
                <Typography variant="h4" component="h4" style={{ color: "#000", fontWeight: "bold" }}>Smart Class</Typography>
                <img src={materialimg} alt="" />
                <Typography variant="h5" component="h5">About</Typography>
                <Typography variant="p" component="p">Smart Classroom is a specially designed computer enabled classroom and has
                  interactive learning software which provides teachers with essential tools to manage classrooms, assess students
                  and encourage collaboration.
                </Typography><br />
                <Typography variant="p" component="p">
                  Smart Classroom is designed to integrate with the existing classroom technology and
                  also with school's wireless or wired network. The need to improve the quality of education to global standards is
                  contributing to the increase in adoption of smart classrooms in India.
                </Typography><br />
                <Typography variant="p" component="p">
                  Smart Classroom helps in making classroom
                  essions more interactive by incorporating live videos, audio, and pictures. This helps in improving students'
                  engagement with learning. With advances in content creation technology and availability of online content, the
                  proportion of digital content in course curriculum has increased.
                </Typography><br />
                <Typography variant="h5" component="h5">Our Smart Class Projects</Typography>
                <ul>
                  <List>86 Smart Classroom in chennai Corporation Schools through ELCOT.</List>
                  <List>171 Adi Dravidar & Tribal Welfare Department schools through ELCOT.</List>
                  <List>25 Smart classrooms through Tirunelveli Corporations.</List>
                  <List>15 Kallar (Most Denoted Backward Class) schools in districts of Madurai, Theni and Dindugal through ELCOT.</List>
                  <List>13 Thanjavur Corporation Schools through ELCOT.</List>
                  <List>4 Smart classrooms through ELCOT by Coimbatore Corporations</List>
                  <List>38 Smart classrooms through Government aided Schools</List>
                  <List>1 Government Children Home for Boys, Ranipet through ELCOT</List>
                  <List>Netcom e-Smart Class Rooms more than 300 Government and Corporation Schools through ELCOT in BOOT model.</List>
                </ul>
              </div>
            </div> */}
          </div>
        </Card>
      </div>
      <div className="shop-container">
        <div className="shop-image">
          <img src={shopimage} alt="" />
          <div className="shop-text">
            <Typography variant="h3" component="h3">Buy All the smart class related products on our Kevin’s e-mart</Typography>
            <div className="show-btn">
              <Button variant="contained" style={{ backgroundColor: "#FFF", color: "#3C3C3C", fontWeight: "bold", textTransform: "initial" }}>Shop Now
                <ShoppingCartIcon style={{ width: "30px" }} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-style">
        <Typography variant="h3" style={{ fontWeight: "bold" }}>Wants to setup smart class or ICT lab ?</Typography>

      </div>
      <div className="request-section">
        <img src={mailbgimg} style={{ width: "100%", position: "relative" }} alt="" />
        <Card className="request-card" style={{ borderRadius: "15px" }}>
          <form style={{ padding: "10px" }}>
            <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
              <TextField
                type="text"
                variant='outlined'
                color='secondary'
                label="First Name"
                onChange={e => setFirstName(e.target.value)}
                value={firstName}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='outlined'
                color='secondary'
                label="Last Name"
                onChange={e => setLastName(e.target.value)}
                value={lastName}
                fullWidth
                required
              />
            </Stack>
            <Stack spacing={2} direction="row" >
              <TextField
                type="email"
                variant='outlined'
                color='secondary'
                label="Enter Your Email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                fullWidth
                required
                sx={{ mb: 4 }}
              />
              {/* <FormLabel>Phone Number</FormLabel> */}
              <TextField
                type="telNo"
                variant='outlined'
                color='secondary'
                label="Enter Your Phone Number"
                onChange={e => setTelNo(e.target.value)}
                value={telNo}
                fullWidth
                required
                sx={{ mb: 4 }}
              />
            </Stack>
            <FormLabel id="demo-row-radio-buttons-group-label">Choose The Service</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="female" control={<Radio />} label="Smart Class Setup" />
              <FormControlLabel value="male" control={<Radio />} label="ICT Lab Setup" />
            </RadioGroup>
            <FormLabel>Comments (If Any)</FormLabel>
            <TextField
              // id="outlined-multiline-static"
              // label="Multiline"
              multiline
              rows={4}
              placeholder="Type something..."
            />
            <Button variant="outlined" color="secondary" type="submit" style={{ textTransform: "initial", border: "3px solid #F97B1D", color: "#000", borderRadius: "20px", float: "right", top: "70px" }}>Request Demo</Button>
          </form>
        </Card>
      </div>
    </>
  );
};

export default Ourservices;