import serviceimage1 from "../../assets/ServiceImages/serviceimage1.png";
import serviceimage2 from "../../assets/ServiceImages/serviceimage2.png";
import serviceimage3 from "../../assets/ServiceImages/serviceimage3.png";

const imageSlide = [
    {
        url: serviceimage1,
        title: "Smart Class Solutions",
        body: "Our smart class solutions are designed to enhance the quality of education by providing smart products for immersive and personalized learning experience. With our smart class solutions, we are paving the way for a smarter future, one that is more accessible, engaging, and effective."
    },
    {
        url: serviceimage2,
        title: "ICT LAB Solutions",
        body: "ComputerEducation. Experience in Facility Management Facility Management: Netcom has supplied and maintained around 1500 systems and Peripherals in 300 Government schools spread across TamilNadu.Netcom maintains the uptime of 97% for the entire IT Infrastructure supplied in these schools forComputer Education."
    },
    {
        url: serviceimage3,
        title: "E-Mart",
        body: "We are also having E-Mart where you can buy thing related for kids, our smart class technical items/our ICT lab related things and some hardware components for computers. And specially we are providing this service for kids and play school related products."
    },
]

export default imageSlide;