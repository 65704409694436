import Navbar from './components/Navbar/Navbar.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Emart from './pages/Emart';
import Home from "./pages/Home";
import Ourservices from './pages/OurServices';
import Contact from './pages/ContactUs';

const App = () => {
  return (
    <>
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" id="home" component={Home} exact>
          <Home />
        </Route>
        <Route path="/services" id="services" component={Ourservices} exact>
          <Ourservices />
        </Route>
        <Route path="/emart" id="emart" component={Emart} exact>
          <Emart />
        </Route>
        <Route path="/contact" id="contact" component={Contact} exact>
          <Contact />
        </Route>
      </Switch>  
    </Router>
    </>
  );
}

export default App;
